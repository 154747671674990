<template>
  <b-card>
    <FormView
      :data="$store.getters['transaction/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'
import { transactionViewPayment } from '@/list/payment-method'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'createdAt',
          label: 'Created at',
          type: 'datetime',
        },
        {
          key: 'orderNumber',
          label: 'Order Number',
        },
        {
          key: 'status',
          label: 'Status',
          type: 'dot',
          sortable: false,
          map: {
            pending: 'warning',
            rejected: 'danger',
            failed: 'danger',
            succeeded: 'success',
            refunded: 'info',
          },
        },
        {
          key: 'title',
          label: 'Type',
        },
        {
          key: 'description',
          label: 'Description',
          visibility: ['view'],
        },
        {
          key: 'userNickname',
          label: 'Username',
          visibility: ['view'],
        },
        {
          key: 'userNickname',
          label: 'User',
          type: 'profile',
          sortable: false,
          url: {
            callback(data) {
              return `/user/${data.userID}`
            },
          },
        },
        // all value is in usd or gold
        {
          key: 'token',
          label: 'Amount',
          type: {
            callback(data) {
              return data.wallet && data.wallet.type === 'credit' ? 'gold' : undefined
            },
          },
          prefix: {
            callback(data) {
              return data.wallet && data.wallet.type === 'earning' ? '$' : undefined
            },
          },
        },
        // all value is in usd
        {
          key: 'price',
          label: 'Currency',
          prefix: '$',
          suffix: ' USD',
          visibility: ['view'],
        },
        {
          key: 'type',
          label: 'Type',
          type: 'badge',
          map: {
            deposit: 'success',
            credit: 'warning',
          },
        },
        transactionViewPayment,
        {
          key: 'updatedAt',
          label: 'Paid at',
          type: 'datetime',
          visibility: ['view'],
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('transaction/getDetail', id)
    },
  },
}
</script>

  <style scoped>

  </style>
